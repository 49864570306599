import { postRequest } from 'utils/rest-api-util';

export interface UserSignUpData {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export const postUserSignUp = async (
  userParams: UserSignUpData,
): Promise<Response> => {
  return await postRequest(`/api/v1/users`, userParams);
};
